import React from "react";
import externalLinkIcon from "../../images/externalLink.png";

const ExternalLinkImageIcon = () => {
  return (
    <img
      className="ml-2"
      src={externalLinkIcon}
      width={15}
      height={15}
      alt=""
    />
  );
};

export default ExternalLinkImageIcon;
