import React from "react";
const Video = ({
  videoSrcURL,
  videoTitle,
  videoPoster,
  controls = true,
  maxVideoHeight,
  ...props
}) => (
  <div className="video">
    {/* <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    /> */}
    <video
      controls={controls}
      autoPlay={false}
      playsInline
      poster={videoPoster}
      width="100%"
      style={{ maxHeight: maxVideoHeight || "100%" }}
    >
      <source src={videoSrcURL} type="video/mp4" />
    </video>
  </div>
);
export default Video;
