import * as React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import HomePage from "../components/HomePage/HomePage";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IndexPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <Header />
      <HomePage />
      <div>
        <Footer />
      </div>
    </main>
  );
};

export default IndexPage;
