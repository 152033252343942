import React from "react";
import Featured from "./Featured";
import * as style from "../../../../src/style.module.css";
import { ContentWrapper } from "../../../shared/components/contentWrapper.styled";
import { SectionTitle } from "../../../shared/components/sectionTitle.styled";

const FeaturedSection = () => {
  return (
    <>
      <div className="p-[2rem]">
        <ContentWrapper>
          <SectionTitle className={`${style.elsie}`}>Featured</SectionTitle>
          <div>
            <Featured />
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default FeaturedSection;
