import React, { useState } from "react";
import * as style from "../../../style.module.css";
import styled from "styled-components";

const TimerBlock = styled.div`
  background-color: #121111;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  font-weight: 900;
  font-family: openSans;
  color: #fff;
`;

export const Timer = ({ data }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
 setInterval(function () {
    var countDownDate = new Date(data.countDown).getTime();
    let now = new Date().getTime();
    var distance = countDownDate - now;
    now = new Date().getTime();
    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
  }, 1000);
  return (
    <>
      <div className={style.openSans}>
        <div className="grid grid-cols-4 gap-4 mt-2 font-bold lg:text-[32px]">
          <TimerBlock>{days}</TimerBlock>
          <TimerBlock>{hours}</TimerBlock>
          <TimerBlock>{minutes}</TimerBlock>
          <TimerBlock>{seconds}</TimerBlock>
        </div>
      </div>
    </>
  );
};
