import React from "react";
import scarf from "../../../images/scarf.svg";
import ethIcon from "../../../images/ethIcon.png";
import dot from "../../../images/Ellipse6.svg";
import * as style from "../../../../src/style.module.css";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { ImageWrapper } from "../../../shared/components/imageWrapper.styled";

const FeaturedWrapper = styled.section``;

const Featured = () => {
  const data = useStaticQuery(graphql`
    {
      featured: allContentfulItems(filter: { isFeatured: { eq: true } }) {
        nodes {
          slug
          price
          title
          collector
          collection {
            title
          }
          image {
            gatsbyImage(width: 400, height: 400)
          }
          marketPlaceIcon {
            gatsbyImage(width: 20, height: 20)
          }
        }
      }
    }
  `);

  const features = data.featured.nodes;

  return (
    <FeaturedWrapper>
      <div className="relative ">
        <div className="grid grid-cols-1 md:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-4">
          {features.map((data, index) => (
            <div className={`mr-4 `} key={index}>
              <div className="shadow-lg rounded-lg mb-10">
                <ImageWrapper>
                  <GatsbyImage
                    image={getImage(data.image)}
                    alt={data.title}
                    className="rounded-t-xl w-full"
                  />
                </ImageWrapper>
                <div className="mt-1 lg:mt-[10px] p-4">
                  <div className={`flex justify-between ${style.poppins}`}>
                    <div className="flex align-center">
                      <div>
                        <img
                          className="mt-1 mr-2"
                          src={scarf}
                          alt="collection"
                        />
                      </div>
                      <p className="text-gray-400 text-[12px] md:text-[12px] lg:text-[14px]">
                        {data.collection.title}
                      </p>
                    </div>
                    <div className="flex align-center">
                      <img
                        className="mr-2 mt-1 h-4 sm:h-3 lg:w-[12px] lg:h-[20px]"
                        src={ethIcon}
                        alt="ethIcon"
                      />
                      <p className=" sm:text-[14px] lg:text-[20px] text-[20px]">
                        {data.price}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="font-bold text-[20px] sm:text-[14px] lg:text-[24px]">
                      {data.title}
                    </p>
                  </div>
                  <div className="flex justify-between mt-8 lg:mt-9">
                    <GatsbyImage
                      image={getImage(data.marketPlaceIcon)}
                      alt={data.title}
                      className="rounded-t-xl"
                    />
                    <div className="flex">
                      <div>
                        <img
                          width={15}
                          className="mt-1 mr-1"
                          src={dot}
                          alt=""
                        />
                      </div>
                      <p className="text-gray-300" style={{ fontSize: "14px" }}>
                        {data.collector}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </FeaturedWrapper>
  );
};

export default Featured;
