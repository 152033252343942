import React from "react";
import * as style from "../../style.module.css";
import { useStaticQuery, graphql } from "gatsby";
import MarketPlace from "./MarketPlace";

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerData: allContentfulHomePageBanner {
        nodes {
          bannerText
          bannerVideo {
            url
          }
          playVideoSound
        }
      }
    }
  `);

  const bannerData = data.bannerData.nodes[0];

  return (
    <>
      <video
        muted
        autoPlay={true}
        loop={true}
        className="invisible md:visible absolute top-0 xl:top-[-50%] right-0 bottom-0 min-w-full min-h-full"
      >
        <source src={bannerData?.bannerVideo?.url} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className="p-4 banner h-[450px] relative md:h-[100vh] flex flex-col items-center justify-center text-center">
        <div className="font-extrabold relative mb-10  p-2 text-[45px] md:text-[72px] xl:text-[92px] tracking-[0.37em]">
          <h1
            className={`${
              style.elsie
            } px-2 center font-extrabold mb-16 uppercase ${
              bannerData?.bannerVideo?.url ? "md:text-white " : "md:text-black"
            } md:bg-transparent shadow-lg rounded-lg`}
          >
            {bannerData.bannerText ?? "THE FOURIV"}
          </h1>
        </div>

        <div
          className="rounded-lg  bottom-[10px] md:bottom-[5%] right-[30px] md:right-[5%] bg-white"
          style={{ position: "absolute" }}
        >
          <MarketPlace />
        </div>
      </div>
    </>
  );
};

export default Banner;
