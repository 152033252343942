import React from "react";
import scarf from "../../../images/scarf.svg";
import ethIcon from "../../../images/ethIcon.png";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as style from "../../../../src/style.module.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Carousel from "../Carousel/Carousel";
import styled from "styled-components";
import { ImageWrapper } from "../../../shared/components/imageWrapper.styled";

const ReservedWrapper = styled.section`
  .slick-slide {
    max-width: 350px;
  }

  .slick-dots button {
    background-color: #e2e2e2 !important;
  }
  li.slick-active button {
    background-color: #2d2d2d !important;
  }
`;

const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        spacing: 2,
        arrows: false,
        spacing: 20,
      },
    },
  ],
  arrows: true,
};

const ReserveData = () => {
  const data = useStaticQuery(graphql`
    {
      reserve: allContentfulReserve {
        nodes {
          name
          price
          marketPlace {
            marketPlaceIcon {
              gatsbyImage(width: 20, height: 20)
            }
          }
          collection {
            title
          }
          image {
            gatsbyImage(width: 400, height: 400)
          }
          externalUrl
        }
      }
    }
  `);
  const reserve = data.reserve.nodes;

  return (
    <ReservedWrapper>
      <div className="relative pl-[1rem] pr-[1rem]">
        <Carousel sliderSettings={sliderSettings}>
          {reserve.map((data, index) => (
            <div key={index} className="mr-4">
              <div className="bg-white shadow-lg rounded-lg">
                <div>
                  <ImageWrapper>
                    <GatsbyImage
                      image={getImage(data.image)}
                      alt={data.name}
                      className="rounded-t-xl w-full"
                    />
                  </ImageWrapper>
                </div>
                <div className="mt-1 p-4 rounded-b-xl">
                  <div className={`flex justify-between ${style.poppins}`}>
                    <div className="flex">
                      <div>
                        <img
                          className="mt-1 mr-2"
                          src={scarf}
                          alt="collection"
                        />
                      </div>
                      <p className="text-gray-400 text-[12px] md:text-[12px] lg:text-[14px]">
                        {data.collection.title}
                      </p>
                    </div>
                    <div className="flex align-center">
                      <img
                        className="mr-2 mt-1 h-4 sm:h-3 lg:w-[12px] lg:h-[20px]"
                        src={ethIcon}
                        alt="ethIcon"
                      />
                      <p className=" sm:text-[14px] lg:text-[20px] text-[20px]">
                        {data.price}
                      </p>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="font-bold text-[20px] sm:text-[18px] lg:text-[24px]">
                      {data.name}
                    </p>
                  </div>
                  <div className="flex justify-between mt-8 align">
                    <div className="w-22 h-22 rounded-full mt-2">
                      <GatsbyImage
                        className="rounded-full"
                        image={getImage(data.marketPlace.marketPlaceIcon)}
                        alt="marketPlace"
                      />
                    </div>
                    {data.externalUrl && (
                      <div>
                        <a
                          href={data.externalUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-[10px] md:text-[12px] pl-4 pr-4 pt-2 pb-2 bg-black text-white rounded-lg"
                        >
                          Buy Now
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </ReservedWrapper>
  );
};

export default ReserveData;
