import React from "react";
import ReserveData from "./ReserveData";
import * as style from "../../../style.module.css";
import { ContentWrapper } from "../../../shared/components/contentWrapper.styled";
import { SectionTitle } from "../../../shared/components/sectionTitle.styled";

const Reserve = () => {
  return (
    <>
      <div className="bg-gray-100 p-[2rem]">
        <ContentWrapper>
          <SectionTitle className={`${style.elsie}`}>Reserve</SectionTitle>
          <ReserveData />
        </ContentWrapper>
      </div>
    </>
  );
};

export default Reserve;
