import React, { useState } from "react";
import * as style from "../../../style.module.css";
import { useStaticQuery, graphql } from "gatsby";
import Modal from "../Modal";
import { Timer } from "./TimeCounter";
import styled from "styled-components";
import { generateExcerpt } from "../../../shared/functions.utils";
import { animated } from "react-spring";
import { Spring } from "react-spring/renderprops";
import Video from "../../../shared/components/Video";
import Carousel from "./Carousel";
import { ContentWrapper } from "../../../shared/components/contentWrapper.styled";
import { SectionTitle } from "../../../shared/components/sectionTitle.styled";

const carousel = {
  background: "black",
  padding: "2rem",
};

const SectionTitleWrapper = styled(SectionTitle)`
  color: #fff;
`;

export const SliderWrapper = styled.section`
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
  .slick-dots {
    position: relative;
    margin: 0;
    top: 20px;
    padding: 1rem 0;

    list-style-type: none;

    li {
      margin: 0 0.05rem;
    }

    button {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      padding: 0;

      border: none;
      border-radius: 100%;
      background-color: #2e2e2e;

      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: #eee;
    }
  }
`;

export const sliderSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  spacing: 2,
  arrows: false,
};

const NotableModal = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  transition: 0.5s;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
  background-color: #000000db;
  left: 0;
`;

const NotableDrops = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const data = useStaticQuery(graphql`
    {
      notableDrops: allContentfulNotableDrops {
        nodes {
          title
          countDown(formatString: "MMM d, yyyy hh:mm:ss ")
          video {
            file {
              url
            }
            title
          }
          releaseDate
          marketPlace {
            marketPlaceLink
            name: marketPlaceName
            image: marketPlaceIcon {
              gatsbyImage(width: 20, height: 20)
            }
          }
          videoTitle
          content {
            content
          }
          videoPoster {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const notableDropsData = data.notableDrops.nodes;
  const closeModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (index) => {
    const data = notableDropsData[index];
    setModalData(data);
    setShowModal(true);
  };

  const AnimatedNotableDropModal = animated(NotableModal);

  return (
    <>
      <div className="relative" style={carousel}>
        <ContentWrapper>
          <SectionTitleWrapper className={`${style.elsie} sm:mt-10`}>
            Notable drops
          </SectionTitleWrapper>
          <Carousel>
            {notableDropsData.map((data, index) => (
              <div key={index} className="md:flex md:grid-cols-1">
                <div className="lg:flex lg:justify-evenly">
                  <div className="w-full lg:min-w-[30%] lg:max-w-[400px]">
                    <Video
                      videoSrcURL={data.video.file.url}
                      videoTitle={data.video.title}
                      videoPoster={data.videoPoster.file.url}
                      controls={true}
                      maxVideoHeight="500px"
                    />
                  </div>
                  <div className="sm:mt-4">
                    {Date.now() < new Date(data.countDown).getTime() && (
                      <Timer data={data} />
                    )}
                  </div>

                  <div className="md:flex">
                    <div className="md:w-1/2 mt-7 ">
                      <div className="md:ml-[20px] lg:ml-[65px] ml-[10px]">
                        <div style={{ color: "white" }}>
                          <div className={`${style.openSans} mb-3`}>
                            <p className="mt-4 text-[20px] lg:text-[26px] font-bold ">
                              {data.title}
                            </p>
                          </div>
                          <div>
                            <p
                              className="text-[16px] lg:text-[18px]  mb-4"
                              style={{ fontWeight: 100 }}
                            >
                              {generateExcerpt(data.content.content, 20)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="md:ml-12 md:mt-10 md:border-l h-min pb-6  lg:border-l-gray-400 mt-6 lg:ml-20 lg:text-[24px]">
                      <div className={` mt-4 ${style.poppins}`}>
                        <button
                          className="bg-white ml-3 lg:ml-10 pl-8 pr-8 pt-2 pb-2 rounded-lg sm:text-[14px]"
                          onClick={() => handleShowModal(index)}
                        >
                          Explore
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>

          <Spring
            from={{ opacity: 0, height: 0 }}
            to={
              showModal
                ? { opacity: 1, height: window.innerHeight }
                : { opacity: 0, height: 0 }
            }
            config={{ duration: 300 }}
          >
            {(props) =>
              showModal && (
                <AnimatedNotableDropModal style={props}>
                  <Modal props={modalData} onClose={closeModal} />
                </AnimatedNotableDropModal>
              )
            }
          </Spring>
        </ContentWrapper>
      </div>
    </>
  );
};

export default NotableDrops;
