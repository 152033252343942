import React from "react";
import * as style from "../../style.module.css";
import CloseButton from "../../shared/components/CloseButton";
import Video from "../../shared/components/Video";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ExternalLinkImageIcon from "../../shared/components/ExternalLinkImageIcon";

const Modal = ({ props, onClose }) => {
  return (
    <div className="overflow-auto absolute left-0 right-0 sm:top-0 md:top-[10%] bottom-0 items-center justify-center">
      <div className="md:w-4/5 m-auto bg-black text-white">
        <div
          className={`fixed right-0 md:right-20 z-10 cursor-pointer text-[24px] font-extrabold text-right pt-[46px] pr-[8%] ${style.openSans}`}
        >
          <CloseButton onClick={onClose} />
        </div>
        <div className="md:flex md:pt-[86px] md:pb-[92px] p-[10%]">
          <div className="w-full md:w-1/2 md:pl-[57px] mt-20 md:mt-0">
            <Video
              videoSrcURL={props.video.file.url}
              videoTitle={props.video.title}
              videoPoster={props.videoPoster.file.url}
            />
            <div className="mt-6 text-[18px]">{props?.title}</div>
            <div
              className="mt-10 mb-10 p-4 text-[14px] md:text-[16px] leading-9 font-thin"
              style={{ backgroundColor: "#151515" }}
            >
              <div className="flex justify-between">
                <p className="font-medium">Release Date:</p>
                <p>{new Date(props?.countDown).toDateString()}</p>
              </div>
              <div className="flex justify-between">
                <p className="font-medium">Market Place:</p>
                <a href={props?.marketPlace.marketPlaceLink} target="_blank">
                  <div className="flex items-center justify-between">
                    <GatsbyImage
                      image={getImage(props?.marketPlace.image)}
                      alt={props?.marketPlace.name}
                      className="rounded-t-xl"
                    />
                    <p className="ml-2">{props?.marketPlace.name}</p>
                    <ExternalLinkImageIcon />
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 md:pl-16 md:pr-[57px] font-thin leading-7">
            {props?.content?.content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
