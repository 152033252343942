import * as React from "react";
import Banner from "./Banner";
import FeaturedSection from "./Features/FeaturedSection";
import Reserve from "./Reserve/Reserve";
import Collections from "./Collections/CollectionSection";
import NotableDrops from "./Carousel/NotableDrops";

const HomePage = () => {
  return (
    <main>
      <title>Home Page</title>
      <Banner />
      <NotableDrops />
      <FeaturedSection />
      <Reserve />
      <Collections />
    </main>
  );
};

export default HomePage;
