import React from "react";
import * as style from "../../style.module.css";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MarketPlace = () => {
  const data = useStaticQuery(graphql`
    {
      marketPlace: allContentfulMarketPlace {
        nodes {
          marketPlaceIcon {
            gatsbyImage(width: 30)
          }
          marketPlaceLink
        }
      }
    }
  `);
  const marketPlace = data.marketPlace.nodes;
  return (
    <>
      <div className="lg:flex lg:justify-end ">
        <div className=" min-w-[20rem] lg:max-w-[35rem] shadow-lg rounded-lg pt-4 pl-4 pr-4 pb-8">
          <div className="md:flex md:justify-between">
            <div>
              <div className="mb-4 text-left">
                <h6 className={`${style.elsie} md:text-[18px] lg:text-[20px]`}>
                  Market places
                </h6>
              </div>
              <div className="flex">
                {marketPlace.map((data, index) => (
                  <a
                    href={data.marketPlaceLink}
                    target="_blank"
                    key={index}
                    className="ml-2 no-underline"
                  >
                    <GatsbyImage
                      image={getImage(data.marketPlaceIcon)}
                      alt="marketPlaces"
                    />
                  </a>
                ))}
              </div>
            </div>
            {/* <div className=" md:w-1/3 border-l border-l-gray-300 ml-1 pl-3">
              <div className="mb-4 mt-4 text-left">
                <h6 className={style.elsie}>Socials</h6>
              </div>
              <img src={twitterIcon} alt="icon" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPlace;
