import React from "react";
import AllCollections from "./AllCollections";
import * as style from "../../../style.module.css";
import { Link } from "gatsby";
import { ContentWrapper } from "../../../shared/components/contentWrapper.styled";
import { SectionTitle } from "../../../shared/components/sectionTitle.styled";

const Collections = () => {
  return (
    <>
      <div className="mt-10 px-[2rem]">
        <ContentWrapper>
          <div className="flex justify-between items-center lg:gap-2">
            <SectionTitle className={`${style.elsie}`}>
              Collections
            </SectionTitle>
            <Link
              to="/collections"
              style={{ letterSpacing: "0.1em" }}
              className=" text-[10px] md:text-[14px] pl-6 pr-6 pt-2 pb-2 bg-black text-white rounded-lg"
            >
              Explore
            </Link>
          </div>
          <AllCollections />
        </ContentWrapper>
      </div>
    </>
  );
};

export default Collections;
