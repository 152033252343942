import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const SliderWrapper = styled.section`
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
  .slick-dots {
    position: relative;
    margin: 0;
    top: 20px;
    padding: 1rem 0;

    list-style-type: none;

    li {
      margin: 0 0.05rem;
    }

    button {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      padding: 0;

      border: none;
      border-radius: 100%;
      background-color: #2e2e2e;

      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: #eee;
    }
  }
`;

const defaulSliderSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  spacing: 2,
  arrows: false,
  spacing: 20,
};

const Carousel = ({ sliderSettings, children }) => {
  return (
    <>
      <div className="relative">
        <SliderWrapper>
          <Slider {...(sliderSettings || defaulSliderSettings)}>
            {children}
          </Slider>
        </SliderWrapper>
      </div>
    </>
  );
};

export default Carousel;
