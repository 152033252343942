import styled from "styled-components";

export const SectionTitle = styled.h4`
  font-size: 24px;
  padding-top: 1em;
  padding-bottom: 1em;

  @media (min-width: 768px) {
    font-size: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;
